/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

// 定义接口，包括 img 标签的标准属性和 objectFit 属性
interface ImageAsBgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  logicWidth: number;
  logicHeight: number;
  borderRadius?: string;
  sx?: SxProps<Theme>;
  imgStyle?: any;
}

export const PersistRatioImage: React.FC<ImageAsBgProps> = ({
  objectFit = 'cover',
  src,
  alt,
  logicWidth,
  logicHeight,
  children,
  sx,
  imgStyle,
  ...props
}) => {
  const paddingTop = Number((logicHeight / logicWidth) * 100).toFixed(2) + '%';
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          width: '100%',
          paddingTop,
        }}
      ></Box>
      <img
        src={src}
        alt={alt}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          objectFit,
          ...imgStyle,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
