import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';
import React from 'react';

export const StyledCarouselWrapper = styled((props: StackProps) => (
  <Stack {...props} />
))(({ theme }) => ({
  width: '100%',
  height: '100%',
  ['.common-carousel-container']: {
    ['.paging-dot']: {
      width: '10px !important',
      height: '10px !important',
      padding: '0',
      borderRadius: '5px',
      margin: '0px 5px',
      backgroundColor: '#979797 !important',
      transition: 'width 0.5s, background-color 0.5s',
      ['&.active']: {
        backgroundColor: '#FCD535 !important',
        width: '28px !important',
      },
      ['svg']: {
        display: 'none',
      },
    },
    ['.paging-dot-container']: {
      top: '10px !important',
    },
  },
}));

export const StyledArrowWrapper = styled((props: StackProps) => (
  <Stack {...props} direction={'row'} gap={2} />
))(({ theme }) => ({
  justifyContent: 'flex-end',
  alignSelf: 'center',
  position: 'relative',
  // ['> *']: {},
  ['svg']: {
    width: '32px',
    height: '32px',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  ['&.left']: {
    left: theme.spacing(-3.5),
    top: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ['&.right']: {
    right: theme.spacing(-3.5),
    top: theme.spacing(-2),
    border: '1px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
