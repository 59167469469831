import { globalModals } from '@/state/globalSettings';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useAtom } from 'jotai';
import useTheme from '@/theme/useTheme';
import useBindEmailOrLogin from '@/hooks/useBindEmailOrLogin';
import React from 'react';
import LocalizedLink from '@/components/ui/LocalizedLink';

const VisitorCards = () => {
  const [t] = useTranslation();

  const cardList = [
    {
      title: t('RAKEBACK REWARDS'),
      subTitle: t('Get money back on every bet with 6% Rakeback!'),
      image: '/assets/images/banner/visitor-cards/bg-1.png',
      link: '/benefits/rakeback',
    },
    {
      title: t('EXCLUSIVE GAMES'),
      subTitle: t('Play exclusive MoonClub original casino games!'),
      image: '/assets/images/banner/visitor-cards/bg-2.png',
      link: '/casino/originals',
    },
    {
      title: t('EARN LIFETIME COMMISSION'),
      subTitle: t('Invite your friends and enjoy over 15% commission!'),
      image: '/assets/images/banner/visitor-cards/bg-3.png',
      link: '/benefits#earn-liftime-commission',
    },
  ];

  useBindEmailOrLogin();

  const { theme } = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      overflow={'hidden'}
      flexDirection={isXS ? 'column' : 'row'}
      justifyContent={'space-between'}
      gap={1}
      alignItems={'center'}
      mt={2.5}
    >
      {cardList.map((card) => {
        return (
          <LocalizedLink
            href={card.link}
            key={card.link}
            style={{
              width: '100%',
              display: 'block',
            }}
          >
            <Box
              key={card.title}
              height={[170, 160]}
              sx={{
                flex: isXS ? 'none' : 1,
                width: '100%',
                background: `url(${card.image}) no-repeat top right`,
                backgroundSize: 'cover',
                padding: '15px 10px',
                borderRadius: isXS ? 0 : '10px',
              }}
            >
              <Typography
                fontWeight={[700, 800]}
                fontSize={'28px'}
                sx={{
                  width: isXS ? '260px' : '220px',
                  lineHeight: 1,
                  fontSize: '28px',
                }}
              >
                {card.title}
              </Typography>

              <Typography
                fontWeight={[600]}
                lineHeight={1.2}
                mt={1}
                mb={[1]}
                fontSize={'16px'}
                sx={{
                  width: isXS ? '140px' : '160px',
                }}
              >
                {card.subTitle}
              </Typography>
            </Box>
          </LocalizedLink>
        );
      })}
    </Stack>
  );
};

export default VisitorCards;
