import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { StyledArrowWrapper, StyledCarouselWrapper } from './styles';

const CommonCarouselItem = ({ children }) => {
  return <div>{children}</div>;
};

interface CommonCarouselWrapperProps {
  slidesToShow?: number;
  wrapAround?: boolean;
  children: React.ReactNode; // children 应该是 CommonCarouselItem 的数组
}

export const CommonCarouselWrapper: React.FC<CommonCarouselWrapperProps> = ({
  slidesToShow = 1,
  wrapAround = false,
  children,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const totalSlides = React.Children.count(children);
  return (
    <StyledCarouselWrapper>
      <Carousel
        defaultControlsConfig={{
          containerClassName: 'common-carousel-container',
          pagingDotsClassName: 'paging-dot',
          pagingDotsContainerClassName: 'paging-dot-container',
        }}
        afterSlide={(slideIndex) => setSlideIndex(slideIndex)}
        slidesToShow={slidesToShow}
        wrapAround={wrapAround}
        renderCenterLeftControls={({ previousSlide }) =>
          slideIndex > 0 && (
            <StyledArrowWrapper className="left" onClick={previousSlide}>
              <KeyboardArrowLeft />
            </StyledArrowWrapper>
          )
        }
        renderCenterRightControls={({ nextSlide }) =>
          slideIndex < totalSlides - 1 && (
            <StyledArrowWrapper className="right" onClick={nextSlide}>
              <KeyboardArrowRight />
            </StyledArrowWrapper>
          )
        }
      >
        {React.Children.map(children, (child, index) => {
          // 确保child是CommonCarouselItem类型，这里可能需要更严格的类型检查或错误处理
          return <CommonCarouselItem key={index}>{child}</CommonCarouselItem>;
        })}
      </Carousel>
    </StyledCarouselWrapper>
  );
};

export default CommonCarouselWrapper;
